import { Button, Switch, message } from 'ant-design-vue'
import DrawerForm from '@/components/DrawerForm'
import FormRadio from '@/components/FormView/FormRadio'
import apiTool from '@/command/apiTool'
import { getAction, putAction } from '@/command/netTool'

let pointGoodsPage = []
let styleConfig = {}

async function onFetchInit(data) {
  const [farmPalmHomePageStyleConfig] = await Promise.all(
    ['/farmPalmHomePageStyleConfig/getDetail'].map((e, i) => getAction(e, {}, '/api'))
  )
  styleConfig = farmPalmHomePageStyleConfig.data
  console.log('datatatat', data)
  pointGoodsPage = data

  let form = {
    ...data,
    ...styleConfig,
    a1: '0',
    // mallPage: switchEcho.data == '0' ? '1' : '0',
  }
  return {
    form,
  }
}

function onClickSave() {
  const length = pointGoodsPage.filter((e) => e.status === '0').length
  console.log(length, length & 1, 'length')
  if (styleConfig.navigationStyleType == '1' && (length & 1) == 0) {
    message.error('舵式导航底部菜单显示必须为奇数')
    return
  }

  putAction('/farmPalmHomePageStyleConfig/update', styleConfig).then(() => {})

  const params = pointGoodsPage
    .filter((e) => !e.seve)
    .map((e, i) => ({
      ...e,
      sort: i,
    }))

  putAction('/config/farmButtomMenu/updateBatch', params).then(() => {
    message.success('保存成功!')
  })
}

function onOpen({ records, update }) {
  apiTool.showDrawer({
    title: '编辑',
    view: DrawerForm,
    width: '600px',
    viewProps: {
      formProps: {
        labelCol: { span: 3 },
        wrapperCol: { span: 21 },
      },
      form: { ...records },
      data: [
        {
          form: [
            {
              name: '标题',
              labelCol: 24,
              wrapperCol: 24,
              key: 'name',
              type: 'input',
              disabled: true,
            },
            {
              name: '自定义标题',
              labelCol: 24,
              wrapperCol: 24,
              key: 'showName',
              type: 'input',
            },
            {
              name: 'ICON选中状态',
              labelCol: 24,
              type: 'iconUpload',
              typeData: [
                {
                  key: 'image2',
                  desc: '@2x   xhdpi <br/>上传尺寸100*100px',
                },
              ],
            },
            {
              name: 'ICON未选中状态',
              labelCol: 24,
              type: 'iconUpload',
              typeData: [
                {
                  key: 'image22',
                  desc: '@2x   xhdpi <br/>上传尺寸100*100px',
                },
              ],
            },
          ],
        },
      ],
    },
    success({ data, setHidden }) {
      update(data).then(setHidden)
    },
    foot: ({ close, submit }) => {
      return [
        {
          name: '返回',
          onClick: close,
        },
        {
          name: '确认',
          type: 'primary',
          onClick: submit,
        },
      ]
    },
  })
}

export default function () {
  const { update } = arguments[0]

  return {
    type: 'cardFormTable',
    url: '/config/farmButtomMenu/list',
    edit: '/config/farmButtomMenu/update',
    onFetchInit,
    data: [
      {
        form: [
          {
            type: 'radioButton',
            cols: 10,
            key: 'navigationStyleType',
            onChange: (val) => {
              styleConfig.navigationStyleType = val
            },
            typeData: [
              {
                name: '普通导航',
                value: '0',
              },
              {
                name: '舵式导航',
                value: '1',
              },
            ],
          },
          {
            type: 'colorPicker',
            cols: 10,
            key: 'navigationColor',
            name: '文字颜色',
            onChange: (val) => {
              styleConfig.navigationColor = val
            },
          },
          {
            type: 'button',
            label: '保存',
            cols: 4,
            key: 'a1',
            props: {
              type: 'primary',
              on: {
                click: () => onClickSave(),
              },
            },
          },
          {
            type: 'table',
            labelCol: { span: 0 },
            wrapperCol: { span: 24 },
            dataSource: pointGoodsPage,
            showFootButton: false,
            showRowSelect: false,
            showPagination: false,
            showHeader: false,
            havedrag: true,
            columns: [
              {
                dataIndex: 'sort',
                width: 30,
                align: 'left',
                customRender: function (text, records, index, h) {
                  return <a-icon type="menu" />
                },
              },
              {
                dataIndex: 'name',
                width: '80%',
                align: 'left',
                customRender: function customRender(text, records, index, h) {
                  return (
                    <div>
                      <img src={records.image2} style={{ width: '20px', height: '20px', marginRight: '10px' }} />
                      <span>{records.showName || records.name}</span>
                    </div>
                  )
                },
              },
              {
                width: '80px',
                dataIndex: 'status',
                customRender: function customRender(text, records, index, h) {
                  return <Switch checked={text === '0'} onClick={() => (records.status = text === '0' ? '1' : '0')} />
                },
              },
              {
                width: '80px',
                customRender: function customRender(text, records, index, h) {
                  return (
                    <Button onClick={() => onOpen({ update, records })} ghost={true} type="primary">
                      编辑
                    </Button>
                  )
                },
              },
            ],
          },
        ],
      },
    ],
  }
}
